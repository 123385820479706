import {
  RemixiconComponentType,
  RiBankCardLine,
  RiGlobalLine,
  RiGroupLine,
  RiQuestionnaireLine,
  RiShieldFlashLine,
  RiSparklingFill,
} from '@remixicon/react'
import { compiler as mdCompiler } from 'markdown-to-jsx'

export const iconMap: Record<string, RemixiconComponentType> = {
  RiGlobalLine,
  RiShieldFlashLine,
  RiBankCardLine,
  RiGroupLine,
  RiQuestionnaireLine,
  RiSparklingFill,
}

export const commands = [
  {
    command: '/config',
    description: 'This command allows you to configure the selected agent.',
  },
  {
    command: '/email',
    description:
      'This command allows you send an email. You must specify recipient email and message content',
  },
  {
    command: '/event',
    description:
      'This command allows you to create a Google Calendar event. You must specify start time, end time, description and summary. Optionally attendees.',
  },
  {
    command: '/export',
    description: 'This command allows you to export the chat to google docs.',
  },
]

export const markdown = (s: string, options?: any): JSX.Element =>
  mdCompiler(s, {
    wrapper: null,
    ...options,
  })

export const linksToOpenInNewTab = (s: string): string =>
  s.replace(
    /\[([^\]]+)\]\((https?:\/\/[^)]+)\)/g,
    '<a href="$2" target="_blank">$1</a>'
  )

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
